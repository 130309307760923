import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Icon, List, Grid, Card, Button } from 'semantic-ui-react';
import TimeAgo from 'timeago-react';
import Header from 'components/Header';
import Message from 'components/Message';
import CancelGoButtons from 'components/CancelGoButtons';
import MicroBillProducts from './ui/MicroBillProducts';
import MicroBillSummary from './ui/MicroBillSummary';
import API from 'utils/api';
import formatter from 'utils/formatter';
import MicroOpenBillCard from './ui/MicroOpenBillCard';
import MicroAddOrdersMenu from './ui/MicroAddOrdersMenu';
import WarningMessage from 'components/WarningMessage';

class MicroBillPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      billId: null,
      bill: {
        name:"",
        orders: []
      },
      ordersInMenu: [],
      showBillReceipt: false,
      showBillDeliveredConfirmation: false,
      showBillNameEdition: false,
      showMenu: false,
      showDeleteWarningMessage: false
    };
  }
  
  componentDidMount() {
    const billId = parseInt(this.props.match.params.billId);
    this.setState({ billId }, this.getBill);
  }

  getBill = () => {
    API.getMicroBill(this.state.billId)
      .then((response) => {
        this.setState({
          bill: response.data,
          ordersInMenu: response.data.orders
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  isMoreThanOneMinuteAgo = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const differenceInSeconds = (now - date) / 1000;
    return differenceInSeconds > 60;
  }

  toggleProductReady = (productId) => {
    API.toggleMicroProductReady(productId)
      .then((response) => {
        this.getBill();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  chargeBill = () => {
    this.setState({ showBillReceipt: true });
  }

  billPaid = () => {
    API.microBillPaid(this.state.billId)
      .then((response) => {
        this.setState({
          showBillReceipt: false
        }, this.getBill);
      })
      .catch((error) => {
        console.error(error);
      })
  }

  deliverBill = () => {
    this.setState({ showBillDeliveredConfirmation: true });
  }

  billDelivered = () => {
    API.microBillDelivered(this.state.billId)
      .then((response) => {
        this.props.history.push('/micro/bills');
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getActionButtons = () => {
    let pendingOrders = this.state.bill.orders.length;
    
    this.state.bill.orders.forEach(order => {
      if (order.ready_at) {
        pendingOrders -= 1;
      }
    });
    
    const ready = pendingOrders === 0;
    
    let buttons = (
      <CancelGoButtons
        cancelFn={ () => this.props.history.push('/micro/bills') }
        cancelText="regresar"
        goText="Ver resumen de cuenta"
        goEnabled={true}
        goFn={this.chargeBill}
      />
    )
    
    // when bill is paid, but not ready yet
    if (this.state.bill.paid_at) {
      buttons = (
        <CancelGoButtons
          cancelFn={ () => this.props.history.push('/micro/bills') }
          cancelText="regresar"
          goText="entregar cuenta"
          goEnabled={ready}
          goFn={this.deliverBill}
        />
      )
    }
    
    return buttons
  }

  showBillNameEdition = () => {
    this.setState({showBillNameEdition: true})
  }

  hideBillNameEdition = () => {
    this.setState({showBillNameEdition: false})
  }

  handleBillName = (event) => {
    let newName = event.target.value;
    console.log("New bill name");
    console.log(newName);
    this.setState(prevState => ({
        bill: {
            ...prevState.bill,
            name: newName
        }
    }));
  }


  updateBillName = () => {
    const params = {
      name: this.state.bill.name
    }
    API.updateBillName(this.state.billId, params)
      .then((response) => {
        console.log(response);
        this.updateBillName();
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  deleteBill = () => {
    console.log("Delete opened bill...")
    API.deleteBill(this.props.match.params.billId)
      .then((response) => {
        console.log("Bill deleted successfully")        
        this.setState({ showDeleteWarningMessage: false }, () => {
          window.location.href = '/micro/bills';
        })
      })
      .catch((error) => {
        console.error(error);
      });
  }
  
  render() {
    if (!this.state.bill) { return null }

    // when an order has been closed (paid and delivered)
    if (this.state.bill.paid_at && this.state.bill.delivered_at) {
      return (
        <div>
          <div>
            <Header text={`#${this.state.billId}`} subtitle={this.state.bill.note} inlineSubtitle={true} />
          </div>

          <div style={{ marginBottom: '1em', fontSize: '24px' }}>
            { formatter.currency(this.state.bill.total) }
          </div>
        
          <Card fluid>
            <Card.Content>
              <List divided relaxed>
                { this.state.bill.orders.map((order) => 
                  <List.Item key={order.id}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={12} verticalAlign="top">
                          <p style={{ fontSize: '1em', marginBottom: 0 }}>
                            { order.product.name }
                          </p>
                          { (order.quantity > 1) &&
                            <p style={{ fontSize: '1em', marginBottom: 0 }}>
                              x {order.quantity}
                            </p>
                          }
                        </Grid.Column>
                        
                        <Grid.Column width={4} verticalAlign="top" textAlign="right">
                          <p style={{ fontSize: '1em', marginBottom: 0, color: (order.quantity > 1 ? 'gray' : '') }}>
                            { formatter.currency(order.product.price) }
                          </p>
                          { (order.quantity > 1) &&
                            <p style={{ fontSize: '1em', marginBottom: 0 }}>
                              {formatter.currency((order.quantity * order.product.price))}
                            </p>
                          }
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </List.Item>
                )}
              </List>
            </Card.Content>
          </Card>

          <div style={{ marginBottom: '0.5em', marginTop: '1.5em', color: 'gray' }}>
            <span>
              <Icon name="clock outline" size='large' />
              <span style={{ marginRight: '5px'}}>
                Entregado
              </span>
              <TimeAgo
                datetime={this.state.bill.delivered_at}
                locale="es"
              />
            </span>
          </div>

          <div style={{ marginBottom: '0.5em', color: 'gray' }}>
            <span>
              <Icon name="clock outline" size='large' />
              <span style={{ marginRight: '5px'}}>
                Pagado
              </span>
              <TimeAgo
                datetime={this.state.bill.paid_at}
                locale="es"
              />
            </span>
          </div>

          <div style={{  color: 'gray' }}>
            <span>
              <Icon name="clock outline" size='large' />
              <TimeAgo
                datetime={this.state.bill.created_at}
                locale="es"
              />
            </span>
          </div>
          
          <Button fluid style={{ marginTop: '2em' }} as={Link} to="/micro/closed-bills">
            regresar
          </Button>
        </div>
      )
    }

    // when an order is being paid
    if (this.state.showBillReceipt) {
      console.log("about to show confirmation to pay")
      console.log("list of products (orders in state)")
      console.log(this.state.bill.orders)
      return (
        <MicroBillSummary
          bill={this.state.bill}
          orders={this.state.bill.orders}
          cancelFn={ () => this.setState({ showBillReceipt: false }) }
          billPaidFn={this.billPaid}
        />
      )
    }

    // when an order is being delivered
    if (this.state.showBillDeliveredConfirmation) {
      const text = `Confirma la entrega de la orden #${this.state.bill.id} para ${this.state.bill.note}.`;
      
      return (
        <div style={{ marginTop: '1em' }}>
          <Message headerText="confirmar entrega de pedido">
            <p style={{ textAlign: 'center', fontSize: '16px' }}>
              Confirma que la cuenta <b>#{this.state.bill.id}</b>
              <br/>
              ha sido entregada
              <br/>
              a <b>{this.state.bill.note}</b>
            </p>
  
            <CancelGoButtons
              cancelFn={ () => this.setState({ showBillDeliveredConfirmation: false }) }
              goText="confirmar entrega"
              goEnabled={true}
              goFn={this.billDelivered}
              warning={true}
            />
          </Message>
        </div>
      )
    }

    // Display a searchable menu to choose products
    if (this.state.showMenu) {
      return (        
        <MicroAddOrdersMenu
          cancelFn={ () => this.setState({ showMenu: false }) }
          billId={this.state.billId}
          getBill={this.getBill}
        />        
      )
    }

    // Display warning message for delete bill
    if (this.state.showDeleteWarningMessage) {
      return (        
        <WarningMessage 
          xFunction={() => this.setState({ showDeleteWarningMessage: false })}
          headerText={"Alerta!"}
          contentText={"Estás seguro que deseas eliminar esta orden?"}
          noFunction={() => this.setState({ showDeleteWarningMessage: false })}
          noBtnText={"No, regresar"}
          yesFunction={this.deleteBill}
          yesBtnText={"Sí, eliminar"}
        />   
      )
    }

    let anyOrderReady = this.state.bill.orders.some(order => order.ready_at !== null && order.ready_at !== '');
    
    return (
      <div>
        {
          this.state.showBillNameEdition ? (
            <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
              <div className="ui input focus" style={{margin:'1.5em 0', width:'66%'}}>
                <input type="text" value={this.state.bill.name} placeholder="Nombre del Cliente" onChange={this.handleBillName}/>
              </div>
              <Button primary onClick={this.updateBillName}>
                Guardar
              </Button>
            </div>
          ) : (
            <div style={{display:'flex', alignItems:'center', justifyContent: 'space-between'}}>
              <Header text={this.state.bill.name ? this.state.bill.name : "Cuenta #" + this.state.bill.id} functionToExecute={this.showBillNameEdition}/>
              <Button primary onClick={this.showBillNameEdition}>Editar Nombre</Button>
            </div>
          )
        }

        {!this.state.bill.paid_at && (
          <Button onClick={() => this.setState({ showMenu: true })} secondary style={{width:'100%'}}>
            <Icon name='plus' />
            Productos
          </Button>
        )} 

        <div style={{ marginTop: '2em' }}>
          <MicroOpenBillCard bill={this.state.bill} />
        </div>

        <MicroBillProducts
          bill={this.state.bill}
          products={this.state.bill.orders}
          toggleProductReadyFn={ (orderId) => this.toggleProductReady(orderId) }
          deliverBillFn={ (billId) => this.deliverBill(billId) }
        />

        { this.getActionButtons() }


        <div style={{display:'flex', marginTop:'1em', justifyContent: 'center'}}>
          {!this.state.bill.paid_at && !anyOrderReady && (            
            <Button color='red' onClick={() => this.setState({ showDeleteWarningMessage: true })} style={{marginRight:'0.25em', width:'100%'}}>
              Eliminar Cuenta
            </Button>
          )}          
        </div>
      </div>
    )
  }
}

export default withRouter(MicroBillPage);