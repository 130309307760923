import React from 'react';
import { useHistory } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import API from 'utils/api';
import authHelpers from 'utils/authHelpers';

export default function LoginButtons(props) { 
  const history = useHistory(); 
  
  const handleLogin = (credentialResponse) => {
    const params = { token: credentialResponse.credential };
    API.signupOrLoginWithGoogle(params)
      .then(response => {
        const token = response.data.token;
        authHelpers.login(token);
        history.push('/micro/bills');
      })
      .catch(error => {
        console.error(error);
      });
  };
  
  return (
    <div style={{ display: 'flex', justifyContent:'center' }}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <GoogleLogin
          onSuccess={handleLogin}
          onError={() => {
            console.log('Login Failed');
          }}
        />
      </GoogleOAuthProvider>
    </div>
  )
}