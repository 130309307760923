import React, {  } from 'react';
import LoginButtons from 'components/LoginButtons';

export default function LoginPage() {

    return (
        <div style={{ textAlign: 'center', paddingTop: '2em', paddingBottom: '2em', maxWidth: '400px', margin: '0 auto' }}>
            <h1>Login to Restaurantero</h1>
            
            <LoginButtons />
        </div>
    );
}
