import React from 'react';
import { Image, Divider } from 'semantic-ui-react';
import businesOwnerImage from 'images/business-owner.png';
import LoginButtons from 'components/LoginButtons';
import NavPublic from 'ui/NavPublic'

class HomePage extends React.Component {
  render() {
    return (
      <div style={{ textAlign: 'center', paddingBottom: '2em', maxWidth: '400px', margin: '0 auto' }}>
        <NavPublic />
        <div style={{ marginBottom: '2em', marginTop: '1em' }}>
          <Image src={businesOwnerImage} fluid />
          <div style={{ marginTop: '3em' }}>
            <h1 style={{ marginBottom: '2.5px' }}>
              restaurantero.io
            </h1>
            <p style={{ color: 'gray' }}>
              para los emprendedores<br/>
              que operan solos su negocio de comida
            </p>
          </div>
        </div>

        <LoginButtons />

        <Divider style={{ margin: '2em 4em' }} />
      </div>
    )
  }
}

export default HomePage;