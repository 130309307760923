import axios from 'axios';
import authHelpers from 'utils/authHelpers';
const baseUrl = process.env.REACT_APP_API_BASE_URL;

const API = {
  
  // AUTH
  signupOrLoginWithGoogle: (params) => {
    const url = `${baseUrl}/auth/google`;
    return axios.post(url, params)
  },
  
  
  // OWNER
  getOwnerSalesReports: (date) => {
    let url = `${baseUrl}/owners/sales?date=${date}`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  getOwnerEstablishmentSalesReport: (establishmentId, date) => {
    const url = `${baseUrl}/owners/sales/${establishmentId}?date=${date}`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  // MICRO
  createMicroBill: (params) => {
    const url = `${baseUrl}/micros/bills`;
    const headers = authHelpers.getAuthHeaders();
    return axios.post(url, params, headers)
  },

  addOrders: (params, billId) => {
    const url = `${baseUrl}/micros/bills/${billId}/add_orders`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, params, headers)
  },

  addOrUpdateProducts: (params, billId) => {
    const url = `${baseUrl}/micros/bills/${billId}/orders`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, params, headers)
  },

  getMicroBills: () => {
    let url = `${baseUrl}/micros/bills`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  getMicroClosedBills: (date) => {
    let url = `${baseUrl}/micros/closed-bills`;

    if (date) {
      const formattedDate = encodeURIComponent(date.toISOString()); // Format the date and encode it
      url += `?date=${formattedDate}`;
    }

    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  getMicroSalesByWeek: () => {
    const url = `${baseUrl}/micros/closed-bills/weekly`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  getMicroSalesByMonth: () => {
    const url = `${baseUrl}/micros/closed-bills/monthly`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  getMicroBill: (billId) => {
    const url = `${baseUrl}/micros/bills/${billId}`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  toggleMicroProductReady: (productId) => {
    const url = `${baseUrl}/micros/orders/${productId}/ready`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, {}, headers)
  },

  microBillPaid: (billId) => {
    const url = `${baseUrl}/micros/bills/${billId}/paid`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, {}, headers)
  },

  microBillDelivered: (billId) => {
    const url = `${baseUrl}/micros/bills/${billId}/delivered`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, {}, headers)
  },

  createMicroProduct: (params) => {
    const url = `${baseUrl}/micros/products`;
    const headers = authHelpers.getAuthHeaders();
    return axios.post(url, params, headers)
  },

  createMicroProductCategory: (params) => {
    const url = `${baseUrl}/micros/product_categories`;
    const headers = authHelpers.getAuthHeaders();
    return axios.post(url, params, headers)
  },

  updateMicroEstablishmentProduct: (productId, params) => {
    const url = `${baseUrl}/micros/products/${productId}`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, params, headers)
  },

  archiveMicroProduct: (productId) => {
    const url = `${baseUrl}/micros/products/${productId}/archive`;
    const headers = authHelpers.getAuthHeaders();
    return axios.post(url, {}, headers)
  },

  updateBillName: (billId, params) => {
    const url = `${baseUrl}/micros/bills/${billId}/name`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, params, headers)
  },

  updateCategoryName: (categoryId, params) => {
    const url = `${baseUrl}/micros/product_categories/${categoryId}`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, params, headers)
  },

  deleteBill: (billId) => {
    const url = `${baseUrl}/micros/bills/${billId}/delete`;
    const headers = authHelpers.getAuthHeaders();
    return axios.post(url, {}, headers)
  },

  deleteCategory: (categoryId) => {
    const url = `${baseUrl}/micros/product_categories/${categoryId}/delete`;
    const headers = authHelpers.getAuthHeaders();
    return axios.post(url, {}, headers)
  },

  getMicroEstablishmentProducts: () => {
    const url = `${baseUrl}/micros/products`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  getMicroEstablishmentProduct: (productId) => {
    const url = `${baseUrl}/micros/products/${productId}`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  getMicroEstablishmentCategories: () => {
    const url = `${baseUrl}/micros/product_categories`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  createMicroEstablishmentCategory: (params) => {
    const url = `${baseUrl}/micros/product_categories`;
    const headers = authHelpers.getAuthHeaders();
    return axios.post(url, params, headers)
  },

  toggleMicroProductAvailability: (productId) => {
    const url = `${baseUrl}/micros/products/${productId}/availability`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, {}, headers)
  },
};

export default API;