import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Menu, Icon } from 'semantic-ui-react';

export default function Nav({ user, links=[] }) {
  const [showMenu, setShowMenu] = useState(false);
  const history = useHistory();

  const toggleShowMenu = () => {
    if (links.length === 0) { return }

    setShowMenu((prevShowMenu) => {
      return !prevShowMenu
    })
  }

  const handleClick = (url) => {
    setShowMenu(false);
    history.push(url);
  }

  const iconName = showMenu ? 'close' : 'bars';
  
  return (
    <>
      <Menu attached borderless compact inverted size="small" color="blue">
        <Menu.Item position='right'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '1em' }}>
              RESTAURANTERO
            </span>
            { (links.length > 0) &&
              <Icon
                name={iconName}
                size="large"
                style={{ cursor: 'pointer' }}
                onClick={toggleShowMenu}
              />
            }
          </div>
        </Menu.Item>
      </Menu>
      { showMenu &&
        <Menu vertical fluid style={{ margin: '0' }}>
          { links.map((link, i) =>
            <Menu.Item key={i} style={{ textAlign: 'right', fontSize: '16px' }}
              onClick={ () => handleClick(link.url) }
            >
              { link.name }
            </Menu.Item>
          )}
        </Menu>
      }
    </>
  ) 
}