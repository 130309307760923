import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import authHelpers from 'utils/authHelpers';
import Nav from 'ui/Nav';
import PoweredBy from 'ui/PoweredBy';

// Micro
export const MicroRoute = ({ children, ...remainingProps }) => {
  const token = authHelpers.getToken();
  
  if (token) {
    const component = React.cloneElement(children);
    
    // Add links here with { name, url }
    const links = [
      { name: 'cuentas abiertas', url: '/micro/bills' },
      { name: 'cuentas cerradas', url: '/micro/closed-bills' },
      { name: 'ventas por semana', url: '/micro/closed-bills/weekly' },
      { name: 'ventas por mes', url: '/micro/closed-bills/monthly' },
      { name: 'categorias', url: '/micro/categories' },
      { name: 'productos', url: '/micro/products' }
    ]; 

    const contentStyle = {
      flexGrow: 1
    };

    return (
      <Route {...remainingProps}>
        <Nav links={links} />
        <Container style={contentStyle}>
          { component }
        </Container>
        <PoweredBy />
      </Route>
    )
  }
  
  return <Redirect to="/" />
}

// Owner
export const OwnerRoute = ({ children, ...remainingProps }) => {
  const user = authHelpers.getUser();
  const token = authHelpers.getToken();
  
  if (token) {
    if (user === 'owner') {
      const component = React.cloneElement(children);
      
      const links = [
        { name: 'ventas totales', url: '/owner/reports/sales' },
      ];
      
      const contentStyle = {
        flexGrow: 1
      };
    
      return (
        <Route {...remainingProps}>
          <Nav user={user} links={links} />
          <Container style={contentStyle}>
            { component }
          </Container>
          <PoweredBy />
        </Route>
      )
    }
  }
  
  return <Redirect to="/" />
}