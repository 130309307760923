import React, { useEffect } from 'react';
import authHelpers from 'utils/authHelpers';

export default function LogoutPage() {

    useEffect(() => {
        authHelpers.logout();
        // Redirect to home and refresh the page
        window.location.href = "/";
    }, []);

    return (
        <h1>Logging out..</h1>
    );
}
