import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Icon } from 'semantic-ui-react';
import TimeAgo from 'timeago-react';
import formatter from 'utils/formatter';

export default function MicroClosedBillCard({ bill }) {
  return (
    <Card
      fluid
      key={bill.id}
      as={Link}
      to={`/micro/bills/${bill.id}`}
      style={{ marginBottom: '1.5em' }}
    >
      <Card.Content
        style={{ cursor: 'pointer' }}
      >
        <Card.Header>
          #{bill.id} / { bill.note }
        </Card.Header>
        <Card.Description>
        <span style={{ fontWeight: 'normal', fontSize: '18px' }}>
          { formatter.currency(bill.total) } pagados
        </span>
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
          <Icon name="clock outline" size='large' />
          <span style={{ marginRight: '5px' }}>entregado</span>
          <TimeAgo
            datetime={bill.delivered_at}
            locale="es"
          />
      </Card.Content>
    </Card>
  )
}