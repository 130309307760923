import React from 'react';
import Nav from 'ui/Nav';

export default function NavPublic(props){

    // Add links here with { name, url }
    const publicLinks = [
        { name: 'Home', url: '/' },
        { name: 'Sign Up', url: '/signup' },
        { name: 'Login', url: '/login' },
        { name: 'Logout', url: '/logout' }
    ]; 

    return(
        <Nav links={publicLinks} />
    )
}